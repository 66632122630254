import React, { useEffect, useState, SyntheticEvent } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button, Input, Modal, TablePaginationConfig, message, Alert, Space, Popconfirm, Col } from 'antd'
import _ from 'lodash'
import classnames from 'classnames'
import companiesFilter from 'common/assets/svg/companiesFilter.svg'
import FancyDrawer from 'common/components/fancy-drawer/fancy-drawer'
import FancyTable from 'common/components/fancy-table/fancy-table'
import { Buttons } from 'common/constants/label.constants'
import c_linkedin from 'common/assets/svg/c_linkedin.svg'
import c_twitter from 'common/assets/svg/c_twitter.svg'
import briefcase from 'common/assets/svg/briefcase.svg'
import location from 'common/assets/svg/location.svg'
import ghost from 'common/assets/svg/ghost.svg'
import { ConfirmBox } from 'common/constants/modal.constants'
import { useDisclaimer } from 'common/hooks/useDisclaimer'

import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import { ContactsTableColumns } from 'features/contacts/components/contacts-table-columns/contacts-table-columns'
// import IntentSignalsPermanentPaywall from 'features/intent-signals/components/paywalls/IntentSignalsPermanentPaywall'
import { SaveFilledModal } from '../intent-signals/save-filters-modal'

import {
  useGetContactMutation,
  useLazyGetContactsByCompanyIdQuery,
  useGetForCompaniesListMutation,
  useLazyGetTelegramContactsQuery,
  // useLazyGetTrackingQuery,
  useLazyGetExposedContactsQuery,
  useSetExposeMutation,
  useLazyGetTakeCreditsQuery,
} from './state/api/ContactsApi'

import { useLazyGetLoginHubSpotQuery, useLazyGetPointsStoreQuery } from '../settings/state/api/SettingsApi'

import ContactDetails from './components/contact-details/contact-details'

import { FilterBar } from 'common/components/FilterBar/FilterBar'
import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import { useActiveFilters } from 'common/hooks/useFilters'
//import { addUserGuideScript } from 'common/utils/userGuideScript'
import { SET_FILTER, SET_LISTS, SET_IS_FILTER_CHANGED } from 'features/intent-signals/state/slice/companiesSlice'
import { useAppDispatch, useAppSelector } from 'state'
import { setSidebarOpen, setTechSidebarOpen } from 'state/filtersSlice/filtersSlice'
import {
  SET_COMPANY_ID,
  SET_SELECTED_CONTACTS,
  SET_SELECTED_CONTACTS_IN_OUTREAH,
  SET_CONTACTS_SELECTED_ROWS,
  SET_DELETE_CONTACTS_SELECTED_ROWS,
  SET_LAST_ACTION_PAGE_OUTREAH,
  SET_LAST_ACTION_PAGE_CONTACT,
} from './state/slice/contactsSlice'
import {
  useGetCompanyMutation,
  useLazyAddNewListQuery,
  useLazyGetListsQuery,
  useLazyUpdateListQuery,
} from '../intent-signals/state/api/CompaniesApi'
import { injectionRegex } from '../../common/static-data/userData'
import { MESSAGE_ERROR } from '../../common/constants/messages.constants'
import { useConnectPlus } from 'common/providers/ConnectPlusProvider'
import { useAuth } from 'common/hooks/useAuth.hooks'
import BlurPaywall from 'features/intent-signals/components/paywalls/BlurPaywall'
import CompanyDetails from 'features/intent-signals/components/company-details/company-details'
import StartScreen from 'features/StartScreen/StartScreen'
import { useShowStartScreen } from 'common/providers/ShowStartScreenProvider'
import OutreachActions from '../Outreach/components/OutreachActions'
import ContactsActions from './components/ContactsActions'
import ErrorMessage from 'features/intent-signals/components/ErrorMessage'
import { outreach } from 'common/constants/outreach.constants'

import styles from './contacts.module.scss'
import CircularImage from './components/contact-details/CircularImage'
import OutreachBanner from 'common/components/TrialBanner/OutreachBanner'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
// import { useLazyGetPointsStoreQuery } from 'features/settings/state/api/SettingsApi'

const ContactsPage = ({
  filterBarValue,
  bottomMenuButton,
  additionalColumns = [],
  handleSelectedContacts,
  isFromOutreach = false,
  setCounts,
}: {
  filterBarValue?: any
  bottomMenuButton?: any
  additionalColumns?: any[]
  handleSelectedContacts?: any
  isFromOutreach?: boolean
  setCounts?: any
}) => {
  const [userPoints, setUserPoints] = useState<any>()
  const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [selectedContacts, setSelectedContacts] = useState<any>([])
  const [inThisPage, setInThisPage] = useState(false)
  const [openBanner, setOpenBanner] = useState(true)
  const [popconfirmLinkedin, setPopconfirmLinkedin] = useState(false)
  const [popconfirmTwitter, setPopconfirmTwitter] = useState(false)
  const [limitOpenOutOfCreditsModal, setLimitOpenOutOfCreditsModal] = useState('points')
  // eslint-disable-next-line
  const [captionCreditsModal, setCaptionCreditsModal] = useState('')

  // const { showDisclaimer, setShowDisclaimer, toggleShowDisclaimer } = useDisclaimer()
  const { showDisclaimer, toggleShowDisclaimer } = useDisclaimer()

  const [showAlert, setShowAlert] = useState(false)
  // eslint-disable-next-line
  const [tokenHubSpot, setTokenHubSpot] = useState(localStorage.getItem('hubspot_access_token'))

  const { filter } = useAppSelector((state) => state.companies)
  const {
    last_action_page_contact,
    last_action_page_outreach,
    delete_contacts_selected_rows,
    my_contact_current_order_by,
  } = useAppSelector((state) => state.contacts)

  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    current: isFromOutreach ? last_action_page_outreach : last_action_page_contact,
    pageSize: 10,
  })
  const navigate = useNavigate()
  const { userDetails, isBetaOrTrialUser, isPaidTrialUser } = useAuth()
  const [userData, setUserData] = useState(userDetails)
  const { selectedId } = useParams()
  const { search, pathname } = useLocation()
  const [selectedRows, setSelectedRows]: any = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false)
  const [refetchGetCompany, { data: company }] = useGetCompanyMutation()
  const [outOfCredits, setOutOfCredits] = useState(false)
  // const [linkedinAlready, setLinkedinAlready] = useState(false)
  const [linkedinAlreadyValue, setLinkedinAlreadyValue] = useState<any>()
  const [twitterAlreadyValue, setTwitterAlreadyValue] = useState<any>()
  const [chargeCredits] = useLazyGetTakeCreditsQuery()

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  // eslint-disable-next-line
  const [errorStatusCode, setErrorStatusCode] = useState(0)
  const [showTelegram, setShowTelegram] = useState(false)
  const [telegramResult, setTelegramResult] = useState('')
  const [showCompanyDetails, setShowCompanyDetails] = useState(false)
  const [showContactDetails, setShowContactDetails] = useState(false)
  const [addToListModal, setAddToListModal] = useState(false)
  const [selectedList, setSelectedList] = useState<string>('')
  const [addToListInput, setAddToListInput] = useState(false)

  const [actionsPaywallOpen, setActionsPaywallOpen] = useState(false)
  const [refetchLoginHubSpot] = useLazyGetLoginHubSpotQuery()

  // const [refetchTracking] = useLazyGetTrackingQuery()
  const { showStartScreen, setShowStartScreen } = useShowStartScreen()
  const [sendRequest, setSendRequest] = useState(false)
  const [openOutOfCreditsModal, setOpenOutOfCreditsModal] = useState(false)
  const [openOutOfCreditsContactModal, setOpenOutOfCreditsContactModal] = useState(false)
  const [openLoadingErrorModal, setOpenLoadingErrorModal] = useState(false)

  // eslint-disable-next-line
  const [showTelegramContacts, setShowTelegramContacts] = useState(false)
  // const [tableParams, setTableParams] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 })

  const fetch = isFromOutreach ? useLazyGetExposedContactsQuery : useLazyGetContactsByCompanyIdQuery
  const [refetchGetContacts, { data: contacts, isLoading, isFetching, error: contactsError }] = fetch()

  const [refetchGetContact, { data: contact, isLoading: loading }] = useGetContactMutation()

  const [refetchExpose, { data: expose }] = useSetExposeMutation()

  // const [userPoints, setUserPoints] = useState<any>()
  // const [refetchGetPointsStore] = useLazyGetPointsStoreQuery()

  //const [refetchTackedCredits] = useGetAlreadyTackedCreditsMutation()

  const [cookies] = useCookies(['selected_companies'])
  const [activeFilters, setActiveFilters] = useActiveFilters()
  const { setConnectPlusSum, setConnectPlusPictures } = useConnectPlus()

  const { selected_companies_details, company_lists, save_filter_properties, is_filter_changed } = useAppSelector(
    (state) => state.companies,
  )
  const { selected_contacts, selected_contacts_in_outreach } = useAppSelector((state) => state.contacts)

  const [refetchUpdateList] = useLazyUpdateListQuery()
  const [refetchAddList] = useLazyAddNewListQuery()
  const [refetchLists] = useLazyGetListsQuery()
  const [refetchGetForCompaniesList] = useGetForCompaniesListMutation()
  const [refetchGetTelegramContacts] = useLazyGetTelegramContactsQuery()

  const [oAllContacts, setOAllContacts] = useState<any>({})
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (contacts) {
      setOAllContacts((prev: any) => ({
        ...prev,
        ...contacts.rows.reduce((acc: any, item: any) => {
          acc[item.index] = item
          return acc
        }, {}),
      }))

      if (setCounts) setCounts({ contactsCount: contacts?.totalNumber, companiesCount: contacts?.companiesCount })

      // setAllContacts((prev: any) => [...prev, ...contacts?.rows])
    }
  }, [contacts])

  useEffect(() => {
    const filteredContacts = Object.values(oAllContacts)?.filter((contact: any) =>
      isFromOutreach
        ? selected_contacts_in_outreach.includes(contact?.index)
        : selected_contacts.includes(contact?.index),
    )
    handleSelectedContacts && handleSelectedContacts(filteredContacts)
    setSelectedContacts(filteredContacts)
  }, [selected_contacts, oAllContacts, selected_contacts_in_outreach])

  useEffect(() => {
    cookies.selected_companies !== undefined && dispatch(SET_COMPANY_ID(cookies.selected_companies?.toString()))
  }, [cookies])

  useEffect(() => {
    if (contacts?.totalNumber) setTableParams({ ...tableParams, total: Number(contacts?.totalNumber) })

    const getTelegram = async () => {
      const { data } = await refetchGetTelegramContacts()

      setConnectPlusSum(data?.totalContacts)
      setConnectPlusPictures(data?.pictures)
    }

    getTelegram()
  }, [contacts])

  useEffect(() => {
    if (!selectedId) return

    const fetchData = async () => {
      setShowContactDetails(true)
      const { error } = await refetchGetContact(selectedId)
      if (error) {
        setOpenOutOfCreditsContactModal(true)
      }
    }

    fetchData()
  }, [selectedId])

  useEffect(() => {
    if (sendRequest === false && selected_companies_details.length !== 0) {
      setActiveFilters({
        companiesName: selected_companies_details.map((item: any) => item.name),
        ...activeFilters,
      })
      setSendRequest(true)
    }
  }, [selected_companies_details])

  const fetchData = async () => {
    if (!Object.keys(activeFilters)?.length && !isFromOutreach) return

    const tempFilters = { ...activeFilters }
    if (tempFilters?.contactsExcludeList) {
      tempFilters.contactsExcludeList = tempFilters.contactsExcludeList?.map((item: any) => item.key)
    }
    if (tempFilters?.contactsIncludeList) {
      tempFilters.contactsIncludeList = tempFilters.contactsIncludeList?.map((item: any) => item.key)
    }
    const result = await refetchGetContacts({
      company_ids: '',
      current: isFromOutreach ? last_action_page_outreach : last_action_page_contact,
      pageSize: tableParams.pageSize,
      filters: isFromOutreach ? '' : _.omitBy(tempFilters, _.isNil),
      order_by: my_contact_current_order_by,
      forceGoldContacts: true,
    })

    setErrorStatusCode(result?.error?.status)
  }

  useEffect(() => {
    isFromOutreach
      ? dispatch(SET_LAST_ACTION_PAGE_OUTREAH(tableParams.current))
      : dispatch(SET_LAST_ACTION_PAGE_CONTACT(tableParams.current))
  }, [tableParams.current])

  useEffect(() => {
    let ids = ''
    selected_companies_details?.forEach((item: any) => {
      if (item.selected && ids.indexOf(item.id) === -1) {
        ids += item.id + ','
      }
    })

    fetchData().catch(console.error)
  }, [
    last_action_page_contact,
    my_contact_current_order_by,
    last_action_page_outreach,
    tableParams?.pageSize,
    showTelegramContacts,
    activeFilters,
  ])

  useEffect(() => {
    if (is_filter_changed && Object.values(filter).length === 0) {
      setSelectedRows([])
      setTableParams({ current: 1, pageSize: 10 })
      dispatch(SET_IS_FILTER_CHANGED(false))
    }
    dispatch(SET_FILTER(activeFilters))
    setShowStartScreen(!Object.keys(activeFilters)?.length && !isFromOutreach)
  }, [activeFilters])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const points_store = await refetchGetPointsStore()
  //     setUserPoints({
  //       points: points_store?.data?.response?.points,
  //       pointsStore: points_store?.data?.response?.pointsStore,
  //     })
  //   }
  //   fetchData().catch(console.error)
  // }, [])

  useEffect(() => {
    if (filter && Object.values(filter).length !== 0) {
      let tempFilter = { ...filter }

      if (filter?.companyList) {
        tempFilter.contactsList = tempFilter.companyList
        delete tempFilter?.companyList
      }

      if (filter?.companyExcludeList) {
        // tempFilter.contactsExcludeList = tempFilter.companyExcludeList
        delete tempFilter?.companyExcludeList
      }

      if (filter?.companyIncludeList) {
        // tempFilter.contactsIncludeList = tempFilter.companyIncludeList
        delete tempFilter?.companyIncludeList
      }
      if (filter?.companyExcludeCampaign) {
        // tempFilter.contactsExcludeCampaign = tempFilter.companyExcludeCampaign
        delete tempFilter?.companyExcludeCampaign
      }

      if (filter?.companyIncludeCampaign) {
        // tempFilter.contactsIncludeCampaign = tempFilter.companyIncludeCampaign
        delete tempFilter?.companyIncludeCampaign
      }

      if (filter?.country) {
        tempFilter.contactsCountry = tempFilter.country
        delete tempFilter.country
      }

      if (filter.searchBarCompany) {
        delete tempFilter?.searchBarCompany
      }

      dispatch(SET_FILTER(tempFilter))
      setActiveFilters(tempFilter)
      if (Object.values(tempFilter).length !== 0) dispatch(SET_IS_FILTER_CHANGED(true))
    }

    !isFromOutreach && dispatch(setSidebarOpen(true))

    //if (userDetails?.did_funnel) addUserGuideScript()
    setTokenHubSpot(localStorage.getItem('hubspot_access_token'))

    setInThisPage(true)
    return () => {
      dispatch(setSidebarOpen(false))
      dispatch(setTechSidebarOpen(false))
    }
  }, [])

  useEffect(() => {
    setSelectedRows([])
  }, [activeFilters, showTelegramContacts])

  useEffect(() => {
    if (inThisPage && delete_contacts_selected_rows) {
      setSelectedRows([])
    }
  }, [delete_contacts_selected_rows])

  useEffect(() => {
    if (delete_contacts_selected_rows) {
      setSelectedRows([])
      isFromOutreach ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH([])) : dispatch(SET_SELECTED_CONTACTS([]))
      dispatch(SET_DELETE_CONTACTS_SELECTED_ROWS(false))
    } else {
      selectedRows?.forEach((item: string) => {
        let selectedRowsList = isFromOutreach ? selected_contacts_in_outreach : selected_contacts

        if (!selectedRowsList.includes(item)) {
          isFromOutreach
            ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH([...selected_contacts_in_outreach, item]))
            : dispatch(SET_SELECTED_CONTACTS([...selected_contacts, item]))
        }
      })
    }
  }, [selectedRows, selected_contacts, selected_contacts_in_outreach])

  const handleSelectedRows = (rows: any, keys: any) => {
    dispatch(SET_CONTACTS_SELECTED_ROWS([]))
    setSelectedRows(keys)
  }

  const handleUnSelectRows = (rows: any) => {
    let arrSelectedCompanies = isFromOutreach ? selected_contacts_in_outreach : selected_contacts

    for (let i = 0; i < rows.length; i++) {
      arrSelectedCompanies = arrSelectedCompanies?.filter((item) => item !== rows[i]?.index)
    }
    isFromOutreach
      ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(arrSelectedCompanies))
      : dispatch(SET_SELECTED_CONTACTS(arrSelectedCompanies))
  }

  const handleRemoveRow = (key: string) => {
    let arr = isFromOutreach
      ? selected_contacts_in_outreach?.filter((e) => e !== key)
      : selected_contacts?.filter((e) => e !== key)

    isFromOutreach ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(arr)) : dispatch(SET_SELECTED_CONTACTS(arr))
  }

  const handleAddItemsToList = async () => {
    const list = company_lists?.filter((item: any) => item.id === selectedList)

    if (list.length > 0) {
      let ids = ''

      isFromOutreach
        ? selected_contacts_in_outreach?.forEach((item: any) => {
            if (item && ids.indexOf(item) === -1) {
              ids += item + ','
            }
          })
        : selected_contacts?.forEach((item: any) => {
            if (item && ids.indexOf(item) === -1) {
              ids += item + ','
            }
          })

      const result = await refetchGetForCompaniesList({
        contact_ids: ids,
      })

      let assignedItems = list[0].company_ids ? list[0].company_ids : ''
      for (let index = 0; index < result?.data?.rows?.length; index++) {
        if (assignedItems.indexOf(result?.data?.rows[index]?.company_id) === -1)
          assignedItems += result?.data?.rows[index]?.company_id + ','
      }

      await refetchUpdateList({ id: selectedList, items_list: assignedItems, field_name: 'company_ids' })

      let con_assignedItems = list[0].contact_ids ? list[0].contact_ids : ''

      isFromOutreach
        ? selected_contacts_in_outreach.forEach((item) => {
            if (con_assignedItems.indexOf(item) === -1) con_assignedItems += item + ','
          })
        : selected_contacts.forEach((item) => {
            if (con_assignedItems.indexOf(item) === -1) con_assignedItems += item + ','
          })

      await refetchUpdateList({ id: selectedList, items_list: con_assignedItems, field_name: 'contact_ids' })
    }

    let getLists = await refetchLists()
    if (getLists) dispatch(SET_LISTS(getLists.data))
    setAddToListModal(false)
    setSelectedRows([])
  }

  const handleAddNewItem = async (event: any) => {
    if (13 === event.keyCode) {
      if (injectionRegex.test(event.target.value)) {
        message.error(MESSAGE_ERROR.INVALID_LIST_NAME)
      } else {
        let checkItem = company_lists.find((item: any) => item.name === event.target.value)

        if (!checkItem) {
          let result = await refetchAddList({ name: event.target.value, type: 2 })
          if (result?.error?.status === 470) {
            setOutOfCredits(true)
          } else {
            if (result) {
              let getLists = await refetchLists()

              if (getLists) dispatch(SET_LISTS(getLists.data))

              message.success(`The list '${event.target.value}' was created successfully`)
              setAddToListInput(!addToListInput)
            }
          }
        } else {
          message.success(`We already have a list with the same name!`)
        }
      }
    }
  }

  const convertString = (str: string) => {
    // eslint-disable-next-line
    const parts = str.replace(/[\[\]"'"]/g, '').split(',')
    const converted = parts.map((part: string) => part.trim()).join(' ')
    return converted
  }

  const tags: string[] = []

  if (contact?.response?.tags) {
    const tagsList = JSON.parse(contact?.response?.tags)
    tagsList.forEach((element: any) => {
      element.value !== '' && element.type !== 'name' && tags.push(convertString(element.value))
    })
  }

  const handleCloseBanner = () => {
    localStorage.setItem('showBanner', 'false')
    setOpenBanner(false)
  }

  useEffect(() => {
    // if (contact?.response?.linkedin_already !== undefined) {
    setLinkedinAlreadyValue(contact?.response?.linkedin_already)
    // }
    // if (contact?.response?.twitter_already !== undefined) {
    setTwitterAlreadyValue(contact?.response?.twitter_already)
    // }
  }, [contact])

  useEffect(() => {
    // setLinkedinAlreadyValue(null)
    // if (expose?.response?.exposeData?.linkedin !== undefined) {
    // setLinkedinAlreadyValue(expose?.response?.exposeData.linkedin)
    // }
    // setTwitterAlreadyValue(null)
    // if (expose?.response?.exposeData?.twitter !== undefined) {
    // setTwitterAlreadyValue(expose?.response?.exposeData.twitter)
    // }
  }, [expose])

  const onClickLinkedIn = (e: SyntheticEvent) => {
    e.preventDefault()
    if (contact?.response?.linkedin_already) {
      window.open(contact?.response?.linkedin_already, '_blank')
    } else {
      if (linkedinAlreadyValue) window.open(linkedinAlreadyValue, '_blank')
      else setPopconfirmLinkedin(true)
    }
  }

  const handleClickLinkedIn = async () => {
    setPopconfirmLinkedin(false)
    const key = 'linkedin'
    const result = await refetchExpose({ selectedId, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('linkedin')
      // message.error(result?.error.data.message)
    } else {
      setLinkedinAlreadyValue(result.data.response.exposeData.linkedin)
      window.open(result.data.response.exposeData.linkedin, '_blank')
    }
  }

  const onClickTwitter = (e: SyntheticEvent) => {
    e.preventDefault()

    // setPopconfirmTwitter(true)
    if (contact?.response?.twitter_already) {
      window.open(contact?.response?.twitter_already, '_blank')
    } else {
      if (twitterAlreadyValue) window.open(twitterAlreadyValue, '_blank')
      else setPopconfirmTwitter(true)
    }
    // refetchTracking({
    //   contact_id: contact?.response?.index,
    //   activity: 'Check Twitter',
    //   twitter: contact?.response?.twitter,
    // })
    // window.open(contact?.response?.twitter, '_blank')
  }

  const handleClickTwitter = async () => {
    setPopconfirmTwitter(false)
    const key = 'twitter'
    // Points Charge Here
    const res = await chargeCredits({
      contact_id: selectedId,
      value: key,
      isDisplayed: null,
      where_opened: 'contact_pannel',
      isCommunitiesAlready: false,
    })

    if (res?.error?.data?.msgStatus === 5) {
      return setOpenOutOfCreditsModal(true)
    }

    const result = await refetchExpose({ selectedId, key })

    if (result?.error?.status === 470) {
      setOpenOutOfCreditsModal(true)
      setLimitOpenOutOfCreditsModal('limit')
      setCaptionCreditsModal('twitter')

      //message.error(result?.error.data.message)
    } else {
      setTwitterAlreadyValue(result.data.response.exposeData.twitter)
      window.open(result.data.response.exposeData.twitter, '_blank')
    }
  }

  const filterBarMessage =
    filterBarValue ||
    (contacts?.rows?.length > 0
      ? 'Select contacts to export'
      : !isLoading
      ? 'No results were found. Please try removing some filters to broaden your search.'
      : 'Select contacts to export')

  const handleLoginHubSpot = async () => {
    const result = await refetchLoginHubSpot()

    const newWindow = window.open(result.data.response, '_blank')

    if (newWindow) {
      newWindow.focus()
    }
  }

  const handleShowCompany = async (company_id: any) => {
    setShowCompanyDetails(false)
    setTimeout(async () => {
      setCompanyDetailsLoading(true)
      setShowCompanyDetails(true)

      const companyDetails = await refetchGetCompany(company_id)

      if (companyDetails) setCompanyDetailsLoading(false)
    }, 500)
  }

  const injectIntoColumns = () => {
    const index = ContactsTableColumns.findIndex((x) => x.key === 'company_name')

    if (index === -1) return ContactsTableColumns

    const newColumns: any = ContactsTableColumns
    newColumns[index].onCell = (record: any) => ({
      onClick: (e: any) => {
        e.stopPropagation()
        handleShowCompany(record?.company_id)
      },
    })

    const tempColumns = [...newColumns, ...additionalColumns]
    if (additionalColumns?.length > 0) {
      const contactNameIndex = tempColumns.findIndex((column) => column.key === 'full_name')
      const outreachedIndex = tempColumns.findIndex((column) => column.key === 'outreached')
      const doxxedIndex = tempColumns.findIndex((column) => column.key === 'is_doxed')
      const outreachedColumn = tempColumns.splice(outreachedIndex, 1)[0]
      tempColumns.splice(doxxedIndex, 1) // Removes Doxxed column
      tempColumns.splice(contactNameIndex + 1, 0, outreachedColumn) // Replacing Outreach Column location
    }

    return tempColumns
  }

  const currentContact = contacts?.rows?.filter((item: any) => item.index === contact?.response?.index)?.[0]

  useEffect(() => {
    if (contactsError) {
      // console.log(contactsError)
      if (contactsError.status === 504 || contactsError.status === 'FETCH_ERROR') {
        setOpenLoadingErrorModal(true)
      } else {
        setOpenOutOfCreditsModal(true)
      }
    }
  }, [contactsError])

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setUserData(data)
    }
    getUserDetails()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const points_store = await refetchGetPointsStore()
      setUserPoints({
        points: points_store?.data?.response?.points,
        pointsStore: points_store?.data?.response?.pointsStore,
      })
    }
    fetchData().catch(console.error)
  }, [])

  // if (contactsError) {
  //   if (contactsError?.status === 470 && userDetails.subscription_type !== 'PREMIUM') {
  //     return (
  //       <div className='flex justify-content-center align-items-center py-3'>
  //         <ErrorMessage />
  //       </div>
  //     )
  //   } else if (contactsError?.status === 500 || userDetails.subscription_type === 'PREMIUM') {
  //     return (
  //       <div className='flex justify-content-center align-items-center'>
  //         <ErrorMessage />
  //       </div>
  //     )
  //   } else if (contactsError?.error.indexOf('NetworkError when attempting to fetch resource.') >= 0) {
  //     return <LoadingBox />
  //   }
  //   return (
  //     <div className='flex justify-content-center align-items-center'>
  //       <ErrorMessage />
  //     </div>
  //   )
  // }

  const handleSelectFifty = () => {
    const newContactsIds = isFromOutreach
      ? [...selected_contacts_in_outreach, ...contacts.contacts_ids]
      : [...selected_contacts, ...contacts.contacts_ids]
    const uniqueContactsIds = Array.from(new Set(newContactsIds))
    isFromOutreach
      ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(uniqueContactsIds))
      : dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
  }

  const handleSelectAllData = () => {
    if (userData.addons.allow_select_all_data) {
      const newContactsIds = isFromOutreach
        ? [...selected_contacts_in_outreach, ...contacts.allContactsIdsData]
        : [...selected_contacts, ...contacts.allContactsIdsData]
      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      isFromOutreach
        ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(uniqueContactsIds))
        : dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all data.')
    }
  }

  const handleSelectAllTelegram = () => {
    if (userData.addons.allow_select_all_telegram) {
      const newContactsIds = isFromOutreach
        ? [...selected_contacts_in_outreach, ...contacts.allContactsIdsTGData]
        : [...selected_contacts, ...contacts.allContactsIdsTGData]
      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      isFromOutreach
        ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(uniqueContactsIds))
        : dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all Telegram prospects.')
    }
  }
  const handleSelectAllLinkedin = () => {
    if (userData.addons.allow_select_all_linkedin) {
      const newContactsIds = isFromOutreach
        ? [...selected_contacts_in_outreach, ...contacts.allContactsIdsLinkedinData]
        : [...selected_contacts, ...contacts.allContactsIdsLinkedinData]
      const uniqueContactsIds = Array.from(new Set(newContactsIds))
      isFromOutreach
        ? dispatch(SET_SELECTED_CONTACTS_IN_OUTREAH(uniqueContactsIds))
        : dispatch(SET_SELECTED_CONTACTS(uniqueContactsIds))
    } else {
      message.warning('Your plan does not support selecting all Linkedin prospects.')
    }
  }
  return (
    <>
      {isFromOutreach && <span style={{ padding: '0px 20px' }}>{filterBarValue}</span>}
      {window.location.pathname.indexOf('outreach') === -1 &&
        openBanner &&
        localStorage.getItem('showBanner') === 'true' &&
        (isBetaOrTrialUser || isPaidTrialUser) && <OutreachBanner handleCloseBanner={handleCloseBanner} />}
      {!showStartScreen && (
        <FilterBar
          showSort={true}
          emptyMessage={showStartScreen ? '' : filterBarMessage}
          deactivate={!filterBarValue}
        />
      )}

      {showStartScreen && !isFromOutreach && (!is_filter_changed || Object.values(filter).length === 0) ? (
        <StartScreen />
      ) : (
        <>
          <div className='flex flex-column flex-1'>
            {contacts?.rows?.length === 0 &&
            !selected_companies_details?.filter((item: any) => item.selected === true)?.length &&
            !activeFilters?.events &&
            !isFetching ? (
              <>
                <p>
                  {!showTelegramContacts && contacts?.totalNumber === '0' && !isFromOutreach && (
                    <div className='emptyContacts' style={{ marginTop: '10%' }}>
                      <img src={ghost} alt={''} />
                    </div>
                  )}
                </p>
              </>
            ) : (
              // ) : isLoading ? (
              //   // <LoadingBox />
              //   <div>loading</div>
              (contacts?.rows?.length > 0 || Object.keys(activeFilters)?.length > 0 || isFromOutreach) && (
                <div className='flex-1'>
                  {!showTelegramContacts && (
                    <>
                      <FancyTable
                        loading={isLoading || isFetching}
                        displayAll={contacts?.displayAll}
                        page_type={'contacts'}
                        totalItems={contacts?.totalNumber}
                        totalDataNumber={contacts?.totalDataNumber || contacts?.totalNumber}
                        key={'index'}
                        // columns={[...injectIntoColumns(), ...additionalColumns]}
                        columns={[...injectIntoColumns()]}
                        data={
                          isFetching
                            ? null
                            : contacts?.rows?.map((contact: any) => ({
                                ...contact,
                                openCompanyDrawer: () => setShowContactDetails(true),
                                key: contact.index,
                              }))
                        }
                        handleShowDetails={async (id) => {
                          navigate(`${pathname}/${id}${search}`)
                        }}
                        tableParams={{
                          ...tableParams,
                          current: isFromOutreach ? last_action_page_outreach : last_action_page_contact,
                        }}
                        // tableParams={tableParams}
                        setTableParams={setTableParams}
                        handleSelectedRows={handleSelectedRows}
                        handleUnSelectRows={handleUnSelectRows}
                        selected_items={isFromOutreach ? selected_contacts_in_outreach : selected_contacts}
                        handleRemoveRow={handleRemoveRow}
                        hasBanner={openBanner}
                        handleSelectFifty={handleSelectFifty}
                        handleSelectAllData={handleSelectAllData}
                        handleSelectAllTelegram={handleSelectAllTelegram}
                        handleSelectAllLinkedin={handleSelectAllLinkedin}
                        isFromOutreach={isFromOutreach}
                      />
                    </>
                  )}
                </div>
              )
            )}
            {/* {contacts?.rows?.length > 0 && ( */}
            {/* <div className={styles.companiesFooter}>
              {bottomMenuButton || <OutreachActions selectedContacts={selectedContacts} />}
              {!isFromOutreach && (
                <Button
                  className='flex align-items-center gap-1'
                  onClick={() => {
                    navigate('/companies')
                  }}>
                  <img src={companiesFilter} alt={''} />
                  <span>{Buttons.COMPANIES}</span>
                </Button>
              )}
            </div> */}
            {/* )} */}

            <Modal
              className='modalErrorMessage'
              title='Choose export destination'
              open={showErrorMessage}
              onCancel={() => {
                setShowErrorMessage(false)
                setErrorMessage('')
              }}
              footer={null}>
              <p>{errorMessage}</p>
            </Modal>

            <Modal
              className='modalTelegram'
              title='Telegram url'
              open={showTelegram}
              onCancel={() => {
                setShowTelegram(false)
                setTelegramResult('')
              }}
              footer={null}>
              <a href={`https://t.me/${telegramResult}`} target='_black'>
                {`https://t.me/${telegramResult}`}
              </a>
            </Modal>

            <Modal
              title='Add to List:'
              className='listModal'
              centered
              open={addToListModal}
              footer={
                <Button
                  className='align-items-center gap-2 addToList'
                  onClick={() => {
                    handleAddItemsToList()
                  }}
                  disabled={!selectedList}>
                  Add to List
                </Button>
              }
              onCancel={() => setAddToListModal(false)}>
              <>
                {company_lists?.map((item: any, index: any) => (
                  <div className='rowListItem' key={index}>
                    <span
                      className={classnames('listitem', selectedList === item.id ? 'selectedList' : '')}
                      onClick={() => {
                        setSelectedList(item.id)
                      }}>
                      {item.name}
                    </span>
                  </div>
                ))}
                {addToListInput && (
                  <div className='addNewContainer'>
                    <Input placeholder='' className='addNewInput' onKeyDown={handleAddNewItem} />
                  </div>
                )}
                <span
                  className='createANewItem'
                  onClick={() => {
                    setAddToListInput(!addToListInput)
                  }}>
                  + Create New
                </span>
              </>
            </Modal>

            {save_filter_properties.open_modal && <SaveFilledModal />}

            {/* {errorStatusCode >= 400 && (
              <div className={styles.centerAlign}>
                {errorStatusCode === 500 || userDetails.subscription_type === 'PREMIUM' ? (
                  <ErrorMessage />
                ) : (
                  <ErrorMessage />
                  // <IntentSignalsPermanentPaywall trialExpired={errorStatusCode === 403} />
                )}
              </div>
            )} */}
          </div>
          <Modal
            open={actionsPaywallOpen}
            onCancel={() => setActionsPaywallOpen(false)}
            okButtonProps={{
              className: 'hidden',
            }}
            cancelButtonProps={{
              className: 'hidden',
            }}>
            <div className='flex justify-content-center'>
              <BlurPaywall
                addon={'This filter'}
                onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
                component={[]}
                customClass='contactSalesNoMargin'
              />
            </div>
          </Modal>

          {showAlert && (
            <Alert
              style={{ position: 'absolute', bottom: '69px', right: ' 20px' }}
              message='Enrich HubSpot with Convrt'
              description='To enrich contacts/companies and create/update deals, connecting your HubSpot account with Convrt is required'
              type='info'
              action={
                <Space direction='vertical'>
                  <Button
                    size='small'
                    type='primary'
                    onClick={() => {
                      handleLoginHubSpot()
                      setShowAlert(false)
                    }}>
                    Connect
                  </Button>
                </Space>
              }
              closable
              onClose={() => setShowAlert(false)}
            />
          )}
        </>
      )}
      <FancyDrawer
        onClose={() => {
          setShowContactDetails(false)
          setPopconfirmLinkedin(false)
          setPopconfirmTwitter(false)
          navigate(-1)
        }}
        extraClass={'fancyDrawer extraFancyDrawer'}
        open={showContactDetails}
        closeIcon={'no'}
        title={
          !loading && (
            <div className={classnames('drawerTitleContact', 'contactLogoTop')}>
              <div className={styles.socialContacts}>
                {/* daniel linkedin*/}
                {contact?.response?.linkedin ? (
                  <>
                    <Popconfirm
                      title='Get Linkedin Details'
                      description={
                        <Col>
                          {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                          {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                          <div>{`This action will cost you ${userPoints?.pointsStore?.linkedin} points. Read disclaimer:`}</div>

                          {showDisclaimer ? (
                            <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                              {ConfirmBox.DISCLAIMER}
                            </p>
                          ) : (
                            <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                              {'Disclaimer'}
                            </span>
                          )}
                        </Col>
                      }
                      open={popconfirmLinkedin && showContactDetails}
                      onConfirm={handleClickLinkedIn}
                      // okButtonProps={{ loading: confirmLoading }}
                      onCancel={() => {
                        setPopconfirmLinkedin(false)
                      }}>
                      <img className='cursor-pointer' src={c_linkedin} onClick={onClickLinkedIn} alt={''} />
                    </Popconfirm>
                    {/* <img className='cursor-pointer' src={c_linkedin} onClick={onClickLinkedIn} alt={''} /> */}
                  </>
                ) : (
                  <img className='imgOpacity' src={c_linkedin} alt={''} />
                )}

                {contact?.response?.twitter ? (
                  <Popconfirm
                    title='Get Twitter Details'
                    description={
                      <Col>
                        {/* <div>{`You will be redirected by this action. Read disclaimer:`}</div> */}
                        <div>{`This action will cost you ${userPoints?.pointsStore?.twitter} points. Read disclaimer:`}</div>
                        {/* <div>{`${ConfirmBox.ARE_YOU_SURE}`}</div> */}
                        {showDisclaimer ? (
                          <p className={'disclaimer disclaimerText'} onClick={toggleShowDisclaimer}>
                            {ConfirmBox.DISCLAIMER}
                          </p>
                        ) : (
                          <span className={'disclaimer disclaimerButton'} onClick={toggleShowDisclaimer}>
                            {'Disclaimer'}
                          </span>
                        )}
                      </Col>
                    }
                    open={popconfirmTwitter && showContactDetails}
                    onConfirm={handleClickTwitter}
                    // okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setPopconfirmTwitter(false)
                    }}>
                    <img className='cursor-pointer' src={c_twitter} onClick={onClickTwitter} alt={''} />
                  </Popconfirm>
                ) : (
                  <img className='imgOpacity' src={c_twitter} alt={''} />
                )}
              </div>
              <div className={styles.actions}>
                <ContactsActions contact={contact} />
              </div>
              {contact &&
                (currentContact?.telegramContact && Object.keys(userDetails?.telegram).length > 0 ? (
                  <div
                    className={styles.hasTelegramImage}
                    style={{
                      border: `3px solid ${
                        currentContact?.connection_level === 1 || contact?.response?.connection_level_already === 1
                          ? '#50e2ba'
                          : currentContact?.connection_level === 2 || contact?.response?.connection_level_already === 2
                          ? '#7043ff'
                          : '#FF4FE6'
                      }`,
                    }}>
                    <CircularImage
                      src={contact?.response?.photo_url}
                      alt=''
                      color={currentContact?.connection_level || contact?.response?.connection_level_already || 3}
                      className={styles.numberCircle}
                      badge={true}
                    />
                  </div>
                ) : (
                  <FancyImage
                    url={contact?.response?.photo_url}
                    name={contact?.response?.full_name}
                    size={90}
                    fontSize={30}
                  />
                ))}

              {/* {contact && <FancyImage url={contact?.response?.photo_url} />} */}
              <p>{contact?.response?.full_name}</p>
              <div className={styles.subTitle}>
                <span>
                  <img className='imgOpacity' src={briefcase} alt={''} />
                  <span>{contact?.response?.job_title}</span>
                </span>
                <span>|</span>
                <span>
                  <img className='imgOpacity' src={location} alt={''} />
                  <span>{contact?.response?.location ? contact?.response?.location : 'NA'}</span>
                </span>
              </div>
              <div>
                {tags?.length > 0 && (
                  <div className={styles.tagListParent}>
                    {tags?.map((tag, index) => (
                      <span className='contactTagsDetails contactTagsRewriteMargin' key={index}>
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )
        }>
        {!loading ? (
          <ContactDetails
            data={contact?.response}
            communitiesData={{
              connection_level: currentContact?.connection_level || contact?.response?.connection_level_already,
              shared_groups: currentContact?.shared_groups,
            }}
            toggleHide={(value: any) => {
              setShowContactDetails(value)
            }}
            refetch={isFromOutreach ? fetchData : ''}
          />
        ) : (
          <LoadingBox />
        )}
        {/* {start && <LoadingBox />} */}
      </FancyDrawer>
      <FancyDrawer
        onClose={() => {
          setShowCompanyDetails(false)
          window.location.pathname.indexOf('companies') === -1 &&
            navigate(`/${isFromOutreach ? 'exposedContacts' : 'contacts'}${search}`)
        }}
        open={showCompanyDetails}
        customClass={'companyDetails'}
        title={
          !companyDetailsLoading && (
            <div className={'drawerTitle'}>
              <FancyImage url={company?.s3_logo} iscompany={true} name={company?.name} />
              <p>
                {company?.name}
                <a href={company?.url} target='_blanck'>
                  {company?.url}
                </a>
              </p>
            </div>
          )
        }>
        {!companyDetailsLoading ? (
          <CompanyDetails
            data={company}
            toggleHide={(value: any) => {
              setShowCompanyDetails(value)
            }}
            showCompany={handleShowCompany}
          />
        ) : (
          <LoadingBox />
        )}
      </FancyDrawer>

      {(openOutOfCreditsModal || openOutOfCreditsContactModal) && (
        <Modal
          centered
          open={openOutOfCreditsModal || openOutOfCreditsContactModal}
          onCancel={() => {
            if (openOutOfCreditsModal) {
              if (limitOpenOutOfCreditsModal === 'points') navigate('/contacts')
              setLimitOpenOutOfCreditsModal('points')
            }
            setOpenOutOfCreditsModal(false)
            setOpenOutOfCreditsContactModal(false)
            setShowContactDetails(false)
          }}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <ErrorMessage
              caption={`Wow, you're really crushing it!`}
              subCaption={`Our system noticed you've hit the limit of plan. Please contact our sales team.`}
              buttonText='Contact Support'
              onClick={() => window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')}
            />
          </div>
        </Modal>
      )}

      {!showStartScreen && (
        <div className={styles.companiesFooter}>
          {bottomMenuButton || (
            <OutreachActions isCompany={false} refetchData={fetchData} selectedContacts={selectedContacts} />
          )}
          {!isFromOutreach && (
            <Button
              className='flex align-items-center gap-1'
              onClick={() => {
                navigate('/companies')
              }}>
              <img src={companiesFilter} alt={''} />
              <span>{Buttons.COMPANIES}</span>
            </Button>
          )}
        </div>
      )}
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={'list'}
          location='lists'
        />
      )}
      {openLoadingErrorModal && (
        <AreYouSureModal
          open={openLoadingErrorModal}
          onClose={() => {
            setOpenLoadingErrorModal(false)
          }}
          title='Oops! It seems like this is taking longer than expected.'
          message="Please try refreshing the page or consider adjusting your filters for a smoother experience. If the issue persists, we're here to help!"
          onConfirm={() => {
            window.location.reload()
          }}
          widthStyle={600}
          confirmText='Refresh'
        />
      )}
    </>
  )
}

export default ContactsPage
