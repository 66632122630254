import React from 'react'
import { Modal } from 'antd'

import OutreachConnectionOnBoarding from './OutreachConnectionOnBoarding'

import styles from './OutreachConnection.module.scss'

interface ConnectPlusModalProps {
  open: boolean
  onClose: () => void
  comingFromConnect?: boolean
  onClosePrevModal?: any
}

const OutreachConnection: React.FC<ConnectPlusModalProps> = ({
  open,
  onClose,
  comingFromConnect,
  onClosePrevModal,
}) => (
  <div className={styles.modal}>
    <Modal
      centered
      open={open}
      okButtonProps={{
        className: 'hidden',
      }}
      cancelButtonProps={{
        className: 'hidden',
      }}
      onCancel={onClose}
      // min-width={1050}
      maskClosable={false}
      className={styles.modal_outreach_connection}
      bodyStyle={{
        height: 590,
        display: 'flex',
        flexDirection: 'column',
      }}>
      <OutreachConnectionOnBoarding
        onClose={onClose}
        comingFromConnect={comingFromConnect}
        onClosePrevModal={onClosePrevModal}
      />
    </Modal>
  </div>
)

export default OutreachConnection
