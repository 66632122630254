import React, { useState } from 'react'
import { Button, Layout, Space, Switch, Tooltip, message } from 'antd'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import info_icon from 'common/assets/svg/info_icon.svg'
import salesforce from 'common/assets/svg/salesforce.svg'
import styles from './SalesforceExportContacts.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { useGetContactsSalesforceMutation } from 'features/contacts/state/api/ContactsApi'

import { post } from 'common/api/axios'

import LoadingBox from 'common/components/LoadingBox/LoadingBox'
import OutOfCreditsModal from 'common/components/OutOfCredits/OutOfCreditsModal'

interface SalesforceExportContactsProps {
  onBack: () => void
  onCloseModal: () => void
  selectedContacts: any
  emailCredits: any
  telegramCredits: any
  userCredits: any
  userPoints: any
}

const SalesforceExportContacts: React.FC<SalesforceExportContactsProps> = ({
  onBack,
  onCloseModal,
  selectedContacts,
  emailCredits,
  telegramCredits,
  userCredits,
  userPoints,
}) => {
  const [outOfCredits, setOutOfCredits] = useState(false)
  const [outOfCreditsType, setOutOfCreditsType] = useState('')
  const [telegramToggle, setTelegramToggle] = useState(true)
  const [emailToggle, setEmailToggle] = useState(true)
  const [overwriteToggle, setOverwriteToggle] = useState(false)
  const [fetchSalesforce] = useGetContactsSalesforceMutation()
  const [isLoading, setIsLoading] = useState(false)

  const handleToggleSwitchTelegram = () => {
    setTelegramToggle(!telegramToggle)
  }

  const handleToggleSwitchEmail = () => {
    setEmailToggle(!emailToggle)
  }

  const onChangeOverwriteToggle = () => {
    setOverwriteToggle(!overwriteToggle)
  }

  const handleExport = async () => {
    if (userPoints.points < userPoints.pointsStore.contacts_export * selectedContacts.length) {
      setOutOfCreditsType('Contacts')
      setOutOfCredits(true)
      return
    } else if (emailToggle && userPoints.points < userPoints.pointsStore.email * emailCredits.length) {
      setOutOfCreditsType('Email')
      setOutOfCredits(true)
      return
    } else if (telegramToggle && userPoints.points < userPoints.pointsStore.telegram * telegramCredits.length) {
      setOutOfCreditsType('Telegram')
      setOutOfCredits(true)
      return
    } else {
      try {
        setIsLoading(true)
        const params = {
          contacts: selectedContacts,
          do_override_if_exists: overwriteToggle,
          emailCredits,
          telegramCredits,
          emailToggle,
          telegramToggle,
        }
        const result = await fetchSalesforce(params)

        if (result?.error?.status === 470) {
          setOutOfCreditsType('Contacts')
          setOutOfCredits(true)
          setIsLoading(false)
        } else if (result.error) {
          message.error(
            `Oops! Your Salesforce enrichment failed to complete. Please try again or contact support if the issue persists.`,
          )
          setIsLoading(false)
        } else {
          message.success(
            `Salesforce enriched! We've added data for ${result.data.addedToSalesforce} contact${
              result.data.addedToSalesforce === 1 ? '' : 's'
            } to your Salesforce`,
          )
          onCloseModal()
        }
      } catch (error) {
        console.error(error)
        message.error('Something went wrong!')
        setIsLoading(false)
      }
    }
  }

  return (
    <>
      {outOfCredits && (
        <OutOfCreditsModal
          open={outOfCredits}
          onClose={() => {
            setOutOfCredits(false)
          }}
          creditType={outOfCreditsType}
          location='salesforce'
        />
      )}
      <Space direction='vertical' style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className={styles.headerStyle}>
            <img src={salesforce} alt='' />
            <h1>Export Contact List</h1>
          </Header>

          <Content className={styles.contentStyle}>
            {isLoading ? (
              <>
                <div className={styles.loading}>
                  <LoadingBox />
                </div>
                <div className={styles.headerLoadingBoxText}>
                  Enriching your Salesforce is in progress. You can close this window - we will notify you when the
                  enrichment is complete
                </div>
              </>
            ) : (
              <div className={styles.container}>
                <div className={styles.header}>
                  <p className={styles.title}>Export Contacts</p>
                  <p className={styles.sub_title}>
                    Export Contacts: {userPoints.pointsStore.contacts_export * selectedContacts.length} points
                  </p>

                  <span className={styles.description}>
                    Additionally, please select your preferred export and expose options.
                  </span>
                </div>
                <div className={styles.switchContainer}>
                  <div className={styles.switchItem}>
                    <Switch onChange={handleToggleSwitchTelegram} checked={telegramToggle} size={'small'} />
                    <span className={styles.switchLabel}>
                      Expose Telegram Accounts ({userPoints.pointsStore.telegram * telegramCredits?.length} Points)
                    </span>
                  </div>
                  <div className={styles.switchItem}>
                    <Switch onChange={handleToggleSwitchEmail} checked={emailToggle} size={'small'} />
                    <span className={styles.switchLabel}>
                      Expose Email Addresses ({userPoints.pointsStore.email * emailCredits?.length} Points)
                    </span>
                  </div>
                </div>
                <div className={styles.terms}>
                  <span>
                    {/* NEED TO OPEN THE MAPPING COMPONENT WHEN A USER CLICKS ON THE MAPPING SETTINGS */}
                    {/* style={{ color: '#7043ff' }} */}
                    The export will be done according to your <span>Mapping Setting</span>.
                  </span>
                </div>
              </div>
            )}
          </Content>

          <Footer className={styles.footerStyle}>
            <div className={styles.section}>
              <div
                className={styles.back}
                onClick={() => {
                  !isLoading && onBack()
                }}>
                <img src={left_arrow} alt='' />
                <span>Back</span>
              </div>

              <div className={styles.centeredText}>
                <Switch
                  style={{ marginRight: '5px' }}
                  onChange={onChangeOverwriteToggle}
                  checked={overwriteToggle}
                  size={'small'}
                  disabled={isLoading}
                />
                <span>Overwrite Populated Fields is {overwriteToggle ? 'ON' : 'OFF'}</span>
                <Tooltip
                  className='tooltipHubspotNewDeal'
                  overlayInnerStyle={{
                    color: 'black',
                    backgroundColor: 'white',
                  }}
                  title='By default, Convrt will not overwrite existing data in populated fields when enriching HubSpot records. It is possible to overwrite populated fields with Convrt data by turning this toggle ON.'>
                  <img src={info_icon} alt='' />
                </Tooltip>
              </div>

              <Button
                className={styles.button}
                onClick={() => {
                  post('/log', {
                    action: ' Export Salesforce Contacts',
                    quantity: selectedContacts?.length,
                  })
                  !isLoading && handleExport()
                }}>
                Export
              </Button>
            </div>
          </Footer>
        </Layout>
      </Space>
    </>
  )
}

export default SalesforceExportContacts
