import { useState, useEffect } from 'react'
import { Button, Checkbox, message } from 'antd'

import FinishPage from './FinishPage'
import LoadingBox from '../LoadingBox/LoadingBox'
import { useAuth } from 'common/hooks/useAuth.hooks'
import OnBoardingPageA from './OnBoardingPages/OnBoardingPageA'
import OnBoardingPageB from './OnBoardingPages/OnBoardingPageB'
import OnBoardingPageC from './OnBoardingPages/OnBoardingPageC'
import OnBoardingPageD from './OnBoardingPages/OnBoardingPageD'
import OnBoardingPageE from './OnBoardingPages/OnBoardingPageE'
import OnBoardingPageF from './OnBoardingPages/OnBoardingPageF'
import OnBoardingPageG from './OnBoardingPages/OnBoardingPageG'

import OnBoardingPaymentPage from './OnBoardingPages/OnBoardingPaymentPage'

import OnBoardingPaymentSuccessfulyPage from './OnBoardingPages/OnBoardingPaymentSuccessfulyPage'
import OnBoardingTelegramAccountSetupPage from './OnBoardingPages/OnBoardingTelegramAccountSetupPage'
import OnBoardingTelegramDetailsPage from './OnBoardingPages/OnBoardingTelegramDetailsPage'

import { get, post, put, telegramPost } from 'common/api/axios'
import { BLACK_LIST_OUTREACH } from '../../static-data/outreach'

import styles from './OutreachConnectionOnBoarding.module.scss'
import purple_back_arrow from '../../assets/svg/purple_back_arrow.svg'
import { useLazyGetSenderQuery } from 'features/Outreach/state/api/OutreachApi'
import { useLocalStorage } from 'common/hooks/useLocalStorage.hooks'
// import { LocalStorageKeys } from 'common/constants/localstorage.constants'

let buttonText: any = ['Start Now']
let stages: any = [OnBoardingPageA, OnBoardingPageC]

interface Props {
  open?: boolean
  onClose: any
  comingFromConnect?: boolean
  onClosePrevModal?: any
}

const OutreachConnectionOnBoarding = ({ onClose: handleClose, comingFromConnect = false, onClosePrevModal }: Props) => {
  // eslint-disable-next-line
  const [user, setUser] = useLocalStorage('user', null)

  const { refetchUserLite, userDetails } = useAuth()
  const [refetcGetSenderList] = useLazyGetSenderQuery()

  const [userName, setUserName] = useState('')
  const [senderName, setSenderName] = useState('')
  const [senderCompany, setSenderCompany] = useState('')

  const [imageUrlS3, setImageUrlS3] = useState('')

  const [telegramUserName, setTelegramUserName] = useState('')
  const [usernameAvailability, setUsernameAvailability] = useState('')

  const [bio, setBio] = useState('')
  const [anyimageUrl, setAnyimageUrl] = useState('')
  const [isSubscribeConnectPlus, setIsSubscribeConnectPlus] = useState(true)
  const [areaCode, setAreaCode] = useState('')
  const [outreachSuite, setOutreachSuite] = useState<any>()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [isExistingCutomer, setIsExistingCustomer] = useState(false)
  const [discountCode, setDiscountCode] = useState('')

  // const [managedPhoneNumber, setManagedPhoneNumber] = useState('')

  const startPage = comingFromConnect ? 1 : 0
  const [step, setStep] = useState(startPage)
  const [radioType, setRadioType] = useState(1) // 1 === PAID , 2 === FREE

  const stagesFree = [
    OnBoardingPageA,
    OnBoardingPageC,
    OnBoardingPageB,
    OnBoardingPageF,
    OnBoardingPageD,
    OnBoardingPageG,
    OnBoardingPageE,
    FinishPage,
  ]

  const stagesPay = [
    OnBoardingPageA,
    OnBoardingPageC,
    OnBoardingPaymentPage,
    OnBoardingPaymentSuccessfulyPage,
    OnBoardingTelegramAccountSetupPage,
    OnBoardingTelegramDetailsPage,
  ]

  const buttonTextFree = [
    'Start Now',
    'Continue',
    'Continue',
    'Validate Code And Connect',
    'Validate Password',
    `Continue`,
    `Done! Let's Connect`,
    `Finalize`,
  ]

  const buttonTextPay = [
    'Start Now',
    'Continue',
    'Continue',
    'Let’s Setup Your Account',
    'All Good, Let’s Go',
    `Finish Setup`,
  ]
  const [loading, setLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isDisabledFreeOutreach, setIsDisabledFreeOutreach] = useState<boolean>(true)
  const [isGetCode, setIsGetCode] = useState<boolean>(false)

  const [outreachSupported, setOutreachSupported] = useState(true)
  const [isDisabledRadio, setIsDisabledRadio] = useState(comingFromConnect ? true : false)

  // const [radioType, setRadioType] = useState(1)

  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  const [validationStatus, setValidationStatus] = useState(false)
  const [validationStatusTelegramAccount, setValidationStatusTelegramAccount] = useState(false)

  const [verificationCode, setVerificationCode] = useState<string>()
  const [telegramPassword, setTelegramPassword] = useState()
  const [isPay, setIsPay] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [isInvalidPassword, setIsInvalidPassword] = useState(false)

  const [groups, setGroups] = useState<any>([])
  const [contacts, setContacts] = useState<any>([])
  const [verificationData, setVerificationData] = useState<{
    hash: string
    session: string
    encrypted_telegram_credential: string
  }>()

  const [formData, setFormData] = useState<any>({
    prefix: '',
    telegramNumber: '',
  })

  const handleComingFromConnect = () => {
    if (comingFromConnect) {
      const user: any = localStorage.getItem('user')
      const userData = JSON.parse(user)

      const hasDialingCode = BLACK_LIST_OUTREACH.some((country) => country.code === userData?.telegram?.area_code)
      setAreaCode(userData?.telegram?.area_code)
      setPhoneNumber(userData?.telegram?.number)

      setIsDisabledRadio(hasDialingCode)
    }
  }

  useEffect(() => {
    buttonText = buttonTextPay
    stages = stagesPay
    const getStatusManageAccounts = async () => {
      try {
        const { data } = await post('/user/getStatusManageAccounts ', { session_id: clientSecret })
        if (data?.response?.status === 'paid') setStep(4)
        if (data?.response?.status === 'info') setStep(6)
        setAreaCode(data?.response?.area_code)
        setPhoneNumber(data?.response?.phone_number)
        setOutreachSuite(data?.outreach_suite === 0 ? 1 : data?.outreach_suite)
        setLoading(false)
        handleComingFromConnect()
      } catch (e) {
        message.error('Something went wrong. Please try again.')
        setLoading(false)
        handleClose()
        onClosePrevModal && onClosePrevModal()
        console.error(e)
      }
    }
    setLoading(true)
    getStatusManageAccounts()
  }, [])

  const handleContactsChange = (e: any) => {
    setContacts(e)
  }

  const handleGroupsChange = (e: any) => {
    setGroups(e)
  }

  const refetchUserData = (userData: any) => {
    const {
      id,
      name,
      email,
      telegram,
      company,
      limit_number,
      company_admin,
      subscription_days,
      subscription_created_at,
      plan_type,
      plan_cycle,
      subscription_type,
      addons,
      credits,
      initial_credits,
      outreach,
      did_funnel,
      funnelData,
      premium_id,
      convrt_points,
      initial_convrt_points,
      dont_show_pricing_page,
    } = userData?.data?.user || {}

    refetchUserLite({
      id,
      name,
      email,
      telegram,
      company,
      limit_number,
      company_admin,
      subscription_days,
      subscription_created_at,
      plan_type,
      plan_cycle,
      did_funnel,
      funnelData,
      subscription_type,
      addons,
      credits,
      initial_credits,
      outreach,
      premium_id,
      convrt_points: +convrt_points,
      initial_convrt_points: +initial_convrt_points,
      flag: dont_show_pricing_page,
    })

    setUser({
      id,
      name,
      email,
      telegram,
      company,
      limit_number,
      company_admin,
      subscription_days,
      subscription_created_at,
      plan_type,
      plan_cycle,
      did_funnel,
      funnelData,
      subscription_type,
      addons,
      credits,
      initial_credits,
      outreach,
      premium_id,
      convrt_points: +convrt_points,
      initial_convrt_points: +initial_convrt_points,
      flag: dont_show_pricing_page,
    })
  }

  const handleStepChange = async () => {
    // let pagingProcess = 1
    let isStep = true
    let area_code = ''
    let number = ''

    // if (step === 0) {
    //   setIsDisabledRadio(false)
    // }
    if (radioType === 2) {
      // FREE
      if (step === 1) {
        setValidationStatus(false)
        if (comingFromConnect) {
          setStep(7)
          await finishOutreach(undefined, undefined, true)
          return
        }
      }
      if (step === 2) {
        if (!(await handleSubmit())) return
      }

      if (step === 3) {
        await verifyCode()
        // await outreachConnection()
        return
      }

      if (step === 4) {
        await verifyPassword()
        await outreachConnection()

        return
      }

      if (step === 5) {
        setIsSubscribeConnectPlus(true)
      }

      if (step === 6) {
        if (!(await setExclusions())) return
      }

      if (step === 7) {
        //handleClose()
        if (comingFromConnect === false) {
          await finish()
        } else {
          handleClose()
          onClosePrevModal()
        }
        return
      }
    } else {
      // PAID
      if (step === 1) {
        const { data }: any = await get('/user/getStripeCustomer')
        const isExistingCutomer = data?.existingCustomer
        setIsExistingCustomer(isExistingCutomer)

        const user = localStorage.getItem('user') || '{}'
        const userData = JSON.parse(user)
        const free_outreach_account = userData.addons?.free_outreach_account
        setIsDisabledFreeOutreach(false)
        setLoading(true)

        setIsDisabledRadio(false)
        if (free_outreach_account !== null && free_outreach_account !== undefined) {
          if (free_outreach_account !== 0) {
            // setIsDisabledFreeOutreach(false)
            try {
              isStep = false
              const data: any = await post('/user/getSessionStatus', {
                session_id: `free-outreach-account-${free_outreach_account}`,
                isFree: true,
              })
              area_code = data?.data.area_code
              number = data?.data.number
              setAreaCode(area_code)
              setPhoneNumber(number)
              // await post('/user/setStatusManageAccounts', {
              //   session_id: `free-outreach-account-${free_outreach_account}`,
              //   status: 'paid',
              //   area_code,
              //   number,
              // })

              // await finishOutreach(area_code, number)
              setStep(3)
            } catch (e) {
              message.error('Something went wrong. Please try again later.')
              console.error(e)
              setIsDisabledFreeOutreach(true)
              onClosePrevModal && onClosePrevModal()
              setLoading(false)
              handleClose()
            }
          }
        }
        setIsDisabledFreeOutreach(true)
        setLoading(false)
      }

      if (step === 2) {
        setIsDisabled(false)
        const getSessionStatus = async () => {
          const data: any = await post('/user/getSessionStatus', { session_id: clientSecret, isFree: false })
          area_code = data?.data.area_code
          number = data?.data.number
          setAreaCode(area_code)
          setPhoneNumber(number)
          if (data.data.status === 'open') {
            setIsPay(true)

            const timeoutIsPay = setTimeout(() => {
              setIsPay(false)
              setIsDisabled(true)

              clearTimeout(timeoutIsPay)
            }, 3000)
            isStep = false
          } else if (data.data.status === 'waiting') {
            setIsWaiting(true)
            const timeoutIsPay = setTimeout(() => {
              setIsWaiting(false)
              setIsDisabled(true)

              clearTimeout(timeoutIsPay)
            }, 3000)
            isStep = false
          } else {
            setIsPay(false)
            // await post('/user/setStatusManageAccounts', {
            //   session_id: clientSecret,
            //   status: 'paid',
            //   area_code,
            //   number,
            // })
            // await finishOutreach(area_code, number)
          }
        }

        if (isExistingCutomer) {
          try {
            const data: any = await post('/user/payForTelegramAccount', {
              session_id: clientSecret,
              type: 'customer_exists',
              discountCode: discountCode,
            })

            area_code = data?.data.area_code
            number = data?.data.number
            setAreaCode(area_code)
            setPhoneNumber(number)

            // await post('/user/setStatusManageAccounts', {
            //   session_id: 'customer_exists',
            //   status: 'paid',
            //   area_code,
            //   number,
            // })
            // await finishOutreach(area_code, number)
          } catch (e: any) {
            setIsDisabled(true)

            return message.error(`${e?.response?.data?.error}`)
          }
        } else {
          await getSessionStatus()
        }
      }

      if (step === 5) {
        setLoading(true)

        post('/log', {
          action: 'Telegram Manage Accounts',
          data: { imageUrlS3, userName, telegramUserName, bio },
        })

        const data = await post('/user/setTelegramManageAccounts', {
          imageUrlS3,
          userName,
          senderName,
          senderCompany,
          telegramUserName,
          bio,
          area_code,
          number,
          session_id: clientSecret,
        })
        setAreaCode(data?.data.area_code)
        setPhoneNumber(data?.data.number)

        await post('/user/updateStatusManageAccounts', { session_id: clientSecret, status: 'info' })

        await post('/user/changeAccountInfo', {
          area_code: data?.data.area_code,
          number: data?.data.number,
          imageUrlS3,
          userName,
          telegramUserName,
          bio,
        })

        await finishOutreach()
        setLoading(true) // ignore the finally
        const userData = await put('/user/updateUserName', {
          area_code: data?.data.area_code,
          number: data?.data.number,
          userName: telegramUserName,
          senderName,
          senderCompany,
        })
        refetchUserData(userData)
        await finishOutreach()
        message.success('Telegram account added successfully')
        setLoading(true) // ignore the finally
        onClosePrevModal && onClosePrevModal()

        handleClose()
        return
      }

      if (step === 6) {
        setLoading(true)
        await post('/user/updateStatusManageAccounts', { session_id: clientSecret, status: 'done' })
        handleClose()
        onClosePrevModal()
        return
      }
    }

    if (isStep) setStep((step) => step + 1)
  }

  const handleStepBackChange = async () => {
    if (step === 2) {
      handleComingFromConnect()
      setOutreachSupported(true)
    }

    setStep((step) => step - 1)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const response = await telegramPost('/getCode', {
        number: formData?.telegramNumber,
        area_code: formData?.prefix?.split('-')?.[0],
      })

      if (response.status !== 200) return false

      const {
        data: { hash, session, encrypted_telegram_credential },
      } = response

      setVerificationData({ hash, session, encrypted_telegram_credential })

      post('/log', {
        action: 'TG Onboarding: Entered Phone Number',
      })

      return true
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleClientSecret = async (id: any) => {
    setClientSecret(id)
  }

  const handleSelectRadio = async (type: any) => {
    try {
      // 1 === PAID | 2 === FREE
      setRadioType(type)
      buttonText = type === 1 ? buttonTextPay : buttonTextFree
      stages = type === 1 ? stagesPay : stagesFree
    } catch (e) {
      console.error(e)
    }
  }

  const verifyCode = async () => {
    let proceed = 0

    try {
      setLoading(true)
      const response = await telegramPost('/signin', {
        ...verificationData,
        code: verificationCode,
        number: formData?.telegramNumber,
        area_code: formData?.prefix,
      })

      if (response?.status === 200) {
        proceed = 2
        await outreachConnection()
      }
    } catch (e: any) {
      console.error(e)

      if (e.response.data.message === 'Two-steps verification is enabled and a password is required') proceed = 1
    } finally {
      setLoading(false)
      setStep((step) => step + proceed)

      await telegramPost('/submitForm', {
        email: userDetails?.email,
        terms: agreedToTerms,
        number: formData?.telegramNumber,
        session: verificationData?.session,
        lastname: userDetails?.name,
        firstname: userDetails?.name,
        area_code: formData?.prefix,
        exclusions: { contact_ids: [], group_ids: [] },
        encrypted_telegram_credential: verificationData?.encrypted_telegram_credential,
      })

      post('/log', {
        action: 'TG Onboarding: Entered Login Code',
      })
    }
  }

  const verifyPassword = async () => {
    try {
      setLoading(true)
      await telegramPost(`/setPassword`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        password: telegramPassword,
        area_code: formData?.prefix,
      })

      setStep((step) => step + 1)
      setIsInvalidPassword(false)
      setLoading(false)
      post('/log', {
        action: 'TG Onboarding: Entered 2FA Password',
      })
    } catch (e) {
      console.error(e)
      // setStep((step) => step - 1)
      setIsInvalidPassword(true)
    } finally {
      // setStep((step) => step + 1)
      //setIsInvalidPassword(false)
      setLoading(false)
    }
  }

  const setExclusions = async () => {
    try {
      setLoading(true)
      const response = await telegramPost('/submitForm', {
        email: userDetails?.email,
        terms: agreedToTerms,
        number: formData?.telegramNumber,
        session: verificationData?.session,
        lastname: userDetails?.name,
        firstname: userDetails?.name,
        area_code: formData?.prefix,
        exclusions: { contact_ids: contacts || [], group_ids: groups || [] },
        encrypted_telegram_credential: verificationData?.encrypted_telegram_credential,
      })

      post('/log', {
        action: 'TG Onboarding: Finished Excluding Groups & Chats',
      })

      return response?.status === 201
    } catch (e) {
      console.error(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const finish = async () => {
    try {
      setLoading(true)
      if (isSubscribeConnectPlus === true) {
        telegramPost(`/fetchContributor`, {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
          userName: radioType === 1 ? 'Warmed' : 'Personal',
        })

        const { data } = await put(`/user/telegram`, {
          email: userDetails?.email,
          number: formData?.telegramNumber,
          area_code: formData?.prefix,
          isUpdateOutreach: false,
          isUpdateTelegram: isSubscribeConnectPlus,
          isManaged: radioType === 1, // If radioType is 1, this is a PAID account, thus isManaged = true
        })
        const {
          id,
          name,
          email,
          telegram,
          company,
          limit_number,
          company_admin,
          subscription_days,
          subscription_created_at,
          plan_type,
          plan_cycle,
          subscription_type,
          addons,
          did_funnel,
          funnelData,
          credits,
          initial_credits,
          outreach,
          premium_id,
          convrt_points,
          initial_convrt_points,
          dont_show_pricing_page,
          linkedin,
          instagram,
        } = data?.user || {}

        if (window.location.pathname === '/outreach/create-new-campaign') await refetcGetSenderList()

        refetchUserLite({
          id,
          name,
          email,
          telegram,
          company,
          limit_number,
          company_admin,
          subscription_days,
          subscription_created_at,
          plan_type,
          plan_cycle,
          did_funnel,
          funnelData,
          subscription_type,
          addons,
          credits,
          initial_credits,
          outreach,
          premium_id,
          convrt_points: +convrt_points,
          initial_convrt_points: +initial_convrt_points,
          flag: dont_show_pricing_page,
          linkedin,
          instagram,
        })
        setUser({
          id,
          name,
          email,
          telegram,
          company,
          limit_number,
          company_admin,
          subscription_days,
          subscription_created_at,
          plan_type,
          plan_cycle,
          did_funnel,
          funnelData,
          subscription_type,
          addons,
          credits,
          initial_credits,
          outreach,
          premium_id,
          convrt_points: +convrt_points,
          initial_convrt_points: +initial_convrt_points,
          flag: dont_show_pricing_page,
          linkedin,
          instagram,
        })
      }

      post('/log', {
        action: 'TG Onboarding: Finished',
      })

      handleClose()
    } catch (e) {
      console.error(`Error fetching contributor: ${e}`)
    } finally {
      setLoading(false)
    }
  }

  const finishOutreach = async (areaCodeArg?: string, phoneNumberArg?: string, personalAccount?: boolean) => {
    try {
      const { data } = await put(`/user/telegram`, {
        email: userDetails?.email,
        number: phoneNumberArg || phoneNumber,
        area_code: areaCodeArg || areaCode,
        isUpdateOutreach: true,
        isUpdateTelegram: false,
        userName: telegramUserName || (personalAccount ? 'Personal' : 'Warmed'),
        isManaged: radioType === 1, // If radioType is 1, this is a PAID account, thus isManaged = true
      })
      const {
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        subscription_type,
        addons,
        did_funnel,
        funnelData,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points,
        initial_convrt_points,
        dont_show_pricing_page,
        linkedin,
        instagram,
      } = data?.user || {}

      if (window.location.pathname === '/outreach/create-new-campaign') await refetcGetSenderList()

      refetchUserLite({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
      })

      setUser({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
      })

      post('/log', {
        action: 'TG Onboarding: Finished',
      })

      // handleClose()
    } catch (e) {
      console.error(`Error fetching contributor: ${e}`)
    } finally {
      setLoading(false)
    }
  }

  const outreachConnection = async () => {
    try {
      //setLoading(true)
      const { data } = await put(`/user/telegram`, {
        email: userDetails?.email,
        number: formData?.telegramNumber,
        area_code: formData?.prefix,
        isUpdateOutreach: true,
        isUpdateTelegram: false,
        userName: telegramUserName || (radioType === 1 ? 'Warmed' : 'Personal'),
        isManaged: radioType === 1, // If radioType is 1, this is a PAID account, thus isManaged = true
      })
      const {
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points,
        initial_convrt_points,
        dont_show_pricing_page,
        linkedin,
        instagram,
      } = data?.user || {}

      if (window.location.pathname === '/outreach/create-new-campaign') await refetcGetSenderList()

      refetchUserLite({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
      })

      setUser({
        id,
        name,
        email,
        telegram,
        company,
        limit_number,
        company_admin,
        subscription_days,
        subscription_created_at,
        plan_type,
        plan_cycle,
        did_funnel,
        funnelData,
        subscription_type,
        addons,
        credits,
        initial_credits,
        outreach,
        premium_id,
        convrt_points: +convrt_points,
        initial_convrt_points: +initial_convrt_points,
        flag: dont_show_pricing_page,
        linkedin,
        instagram,
      })
    } catch (e) {
      console.error(`Error fetching contributor: ${e}`)
    } finally {
      setLoading(false)
    }
  }

  const handleFormDataChange = (data: any) => {
    setFormData((formData: any) => ({ ...formData, ...data }))
    const isTelegramNumber = /^[0-9]{4,}$/.test(data.telegramNumber)

    const hasDialingCode = BLACK_LIST_OUTREACH.some((country) => country.code === data.prefix)
    setOutreachSupported(!hasDialingCode)
    setValidationStatus(isTelegramNumber && !hasDialingCode)

    // setIsSubscribeConnectPlus(data.checked)
  }

  const handleFormTelegramProfileDataChange = (
    data: any,
    anyimageUrlVal: any,
    userNameVal: any,
    telegramVal: any,
    bioVal: any,
    imageUrlS3Val: any,
    senderNameVal: any,
    senderCompanyVal: any,
    usernameAvailability: any,
  ) => {
    setUserName(userNameVal)
    setSenderName(senderNameVal)
    setSenderCompany(senderCompanyVal)
    setTelegramUserName(telegramVal)
    setBio(bioVal)
    setAnyimageUrl(anyimageUrlVal)
    setValidationStatusTelegramAccount(data)
    setImageUrlS3(imageUrlS3Val)
    setUsernameAvailability(usernameAvailability)
  }

  const handleCheckboxChange = (e: any) => {
    setAgreedToTerms(e.target.checked)
  }

  const handleGetChatLoginCode = async (isGetCode: any) => {
    try {
      //setRadioType(type)
      setIsGetCode(isGetCode)
    } catch (e) {
      console.error(e)
    }
  }

  const Stage = stages[step] || stages[0]

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <div className={styles.container}>
          <Stage
            formData={formData}
            handleSubmit={handleSubmit}
            handleSelectRadio={handleSelectRadio}
            handleClientSecret={handleClientSecret}
            handleGroupsChange={handleGroupsChange}
            setTelegramPassword={setTelegramPassword}
            handleFormDataChange={handleFormDataChange}
            handleFormTelegramProfileDataChange={handleFormTelegramProfileDataChange}
            handleContactsChange={handleContactsChange}
            handleVerificationCompletion={setVerificationCode}
            selectedGroups={groups}
            selectedContacts={contacts}
            anyimageUrl={anyimageUrl}
            imageUrlS3={imageUrlS3}
            userName={userName}
            senderName={senderName}
            senderCompany={senderCompany}
            telegram={telegramUserName}
            usernameAvailability={usernameAvailability}
            bio={bio}
            areaCode={areaCode}
            phoneNumber={phoneNumber}
            handleGetChatLoginCode={handleGetChatLoginCode}
            isDisabledRadio={isDisabledRadio}
            setDiscountCodeForPayment={setDiscountCode}
            outreachSuite={outreachSuite}
            // setIsExistingCustomer={setIsExistingCustomer}
          />
          {radioType === 2 && step === 2 && !outreachSupported && (
            <div className={styles.outreachSupported}>
              {'Telegram outreach is not supported in your region, '}
              <span className={styles.dedicated}>{'Use dedicated Outreach account instead'}</span>
            </div>
          )}

          {isDisabledRadio && (
            <div className={styles.outreachSupportedPageC}>
              {'Telegram outreach is not supported in your region, '}
              <span className={styles.dedicated}>{'Use dedicated Outreach account instead'}</span>
            </div>
          )}

          {radioType === 2 && step === 3 && (
            <div className={styles.terms}>
              <Checkbox onChange={handleCheckboxChange}>
                <span>{`I agree to the `}</span>
                <a href='https://www.convrt.io/terms-of-use/' target='_blank' rel='noreferrer'>
                  {`Terms & Conditions`}
                </a>
              </Checkbox>
            </div>
          )}

          <div
            className={
              (step === 5 && radioType === 2) || (step === 2 && radioType === 2) || (step === 5 && radioType === 1)
                ? styles.footer
                : styles.footer_center
            }>
            {step === 2 || (step === 5 && radioType === 1) ? (
              <Button
                style={step === 2 && radioType === 1 ? { position: 'absolute', bottom: '20px', left: '30px' } : {}}
                onClick={handleStepBackChange}
                className={styles.modaBackButton}>
                <img src={purple_back_arrow} alt='' style={{ marginRight: '20px' }} />

                {step === 2 || (step === 5 && radioType === 2) ? 'Back' : 'Edit'}
              </Button>
            ) : (
              <></>
            )}

            {isPay && (
              <p style={{ position: 'absolute', bottom: '70px', left: '205px', color: 'red' }}>
                To continue the process you are required to pay
              </p>
            )}

            {isWaiting && (
              <p style={{ position: 'absolute', bottom: '70px', left: '205px', color: 'red' }}>
                Please wait while we validate your payment.
              </p>
            )}

            {isInvalidPassword && (
              <p style={{ position: 'absolute', bottom: '270px', left: '300px', color: 'red' }}>
                Invalid password, please try again
              </p>
            )}

            {step === 5 && radioType === 2 ? (
              <Button
                // style={step === 2 && radioType === 1 ? { position: 'absolute', bottom: '20px', left: '30px' } : {}}
                onClick={() => {
                  setIsSubscribeConnectPlus(false)
                  setStep((step) => step + 2)
                }}
                className={styles.modaBackButton}>
                {"I'm not interested in Connect+ Powers"}
              </Button>
            ) : (
              <></>
            )}

            {radioType !== 3 ? (
              loading ? (
                <></>
              ) : (
                <Button
                  style={
                    step === 2 && radioType === 1
                      ? isExistingCutomer
                        ? { position: 'absolute', bottom: '20px' }
                        : { position: 'absolute', bottom: '20px', left: '230px' }
                      : {}
                  }
                  onClick={handleStepChange}
                  disabled={
                    (radioType === 2 && step === 2 && !validationStatus) ||
                    (radioType === 2 && step === 3 && (!agreedToTerms || !verificationCode)) ||
                    (radioType === 1 && step === 4 && !validationStatusTelegramAccount) ||
                    (radioType === 1 && step === 6 && !isGetCode) ||
                    (radioType === 1 && step === 2 && !isDisabled) ||
                    (step === 1 && !isDisabledFreeOutreach)
                  }
                  className={styles.modalButton}>
                  {buttonText[step]}
                </Button>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default OutreachConnectionOnBoarding
