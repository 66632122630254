import React from 'react'
import { List, Tooltip } from 'antd'
import full_message_indicator from 'common/assets/svg/full_message_indicator.svg'
import empty_message_indicator from 'common/assets/svg/empty_message_indicator.svg'
import { InfoCircleOutlined } from '@ant-design/icons'
import './ScoreDetailsList.scss'

interface ScoreDetail {
  action: string
  points: number
}

interface ScoreDetailsListProps {
  scoreDetails: ScoreDetail[]
  outreachScore: number
}

const ScoreDetailsList: React.FC<ScoreDetailsListProps> = ({ scoreDetails, outreachScore }) => {
  const listDetails = (
    <div className={`score-details-content`}>
      <p className='score-details-subtitle'>Optimize your messages for maximize sending and response rate</p>
      <List
        itemLayout='horizontal'
        dataSource={scoreDetails}
        renderItem={(item: ScoreDetail) => (
          <List.Item className='score-details-item'>
            <List.Item.Meta
              avatar={<img src={item.points > 0 ? full_message_indicator : empty_message_indicator} alt='' />}
              title={
                <div className='score-details-title'>
                  <span>{item.action}</span>
                  <span className={item.points > 0 ? 'score-positive' : 'score-negative'}>
                    {item.points > 0 ? `+${item.points}` : `${item.points}`}
                  </span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  )

  const displayedScore = Math.min(outreachScore, 100)

  return (
    <div className='score-details-container'>
      <div className='score-details-header'>
        <span>Outreach Score</span>
        <span
          className={`score ${
            displayedScore < 50 ? 'score-red' : displayedScore <= 70 ? 'score-yellow' : 'score-green'
          }`}>
          {displayedScore}
        </span>
        <Tooltip
          title={listDetails}
          placement='bottom'
          trigger={['hover']}
          color='transparent'
          overlayInnerStyle={{ boxShadow: 'none', width: '400px' }}>
          <InfoCircleOutlined className='icon-detail' />
        </Tooltip>
      </div>
    </div>
  )
}

export default ScoreDetailsList
